.App {
  text-align: center;
}

body {
  /* background-image: linear-gradient(
    to left bottom,
    #250730,
    #1e0828,
    #18081f,
    #110716,
    #070509
  ); */
}

* {
  margin: 0;
  scroll-behavior: smooth;
  font-family: "Segoe UI", sans-serif;
}

/* Hide scrollbar for Chrome, Safari and Opera */

h1 {
  font-family: "Rajdhani", sans-serif;
}
h2,
h3,
h4,
h5,
h6 {
  font-family: "Montserrat", sans-serif;
}
p {
  font-family: "Lato", sans-serif;
}
